import React from 'react';
import './SoonGameCard.scss';

export default function SoonGameCard() {
  return (
    <div className="soonGameCard">
      <div className="soonGameCard__title">Готуємо для вас нові пригоди!</div>
    </div>
  );
}
